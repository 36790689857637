import { Admin, Resource } from "react-admin";
import simpleRestProvider from "ra-data-simple-rest";
import polyglotI18nProvider from "ra-i18n-polyglot";

import Dashboard from "./pages/dashboard/Dashboard";
import englishMessages from "./i18n/en";
import customers from "./pages/customers";

const dataProvider = simpleRestProvider("https://api.thismano.com/admin");

const i18nProvider = polyglotI18nProvider(
  () => {
    // Always fallback on english
    return englishMessages;
  },
  "en",
  [{ locale: "en", name: "English" }]
);

const App = () => (
  <Admin
    dataProvider={dataProvider}
    dashboard={Dashboard}
    i18nProvider={i18nProvider}
  >
    <Resource name="customers" {...customers} />
  </Admin>
);

export default App;
